ssab-cx-customer-emulation {
  display: flex;

  @media (max-width: 575px) {
    flex-direction: column;
    > * {
      margin-bottom: 12px;
    }
  }

  @media (min-width: 575px) {
    input {
      flex: 1;
    }
  }
  button {
    @media (min-width: 575px) {
      margin-inline-start: 8px;
    }

    padding-inline-start: 35px;
    color: #bb0000;
    border-color: #bb0000;

    background: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%23bb0000' d='M14.8,8c0-3.7-3-6.8-6.8-6.8S1.3,4.2,1.3,8s3,6.8,6.8,6.8S14.8,11.7,14.8,8z M2.6,8c0-3,2.4-5.5,5.5-5.5S13.5,5,13.5,8 s-2.4,5.5-5.5,5.5S2.6,11,2.6,8z M10.7,5.8v4.4c0,0.2-0.2,0.4-0.4,0.4H5.9c-0.2,0-0.4-0.2-0.4-0.4V5.8c0-0.2,0.2-0.4,0.4-0.4h4.4 C10.5,5.4,10.7,5.6,10.7,5.8z'/%3E%3C/svg%3E%0A")
      no-repeat 10px center;

    &:hover {
      background: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='white' d='M14.8,8c0-3.7-3-6.8-6.8-6.8S1.3,4.2,1.3,8s3,6.8,6.8,6.8S14.8,11.7,14.8,8z M2.6,8c0-3,2.4-5.5,5.5-5.5S13.5,5,13.5,8 s-2.4,5.5-5.5,5.5S2.6,11,2.6,8z M10.7,5.8v4.4c0,0.2-0.2,0.4-0.4,0.4H5.9c-0.2,0-0.4-0.2-0.4-0.4V5.8c0-0.2,0.2-0.4,0.4-0.4h4.4 C10.5,5.4,10.7,5.6,10.7,5.8z'/%3E%3C/svg%3E%0A")
        no-repeat 10px center #bb0000;

      color: #fff;
      fill: #fff;
    }
  }
}

//Session-in-progress alert
.asm-alert {
  padding: 9px 12px;
  border-radius: 4px;
  border: 1px solid #89919a;
  background-color: #f4f4f4;
  color: #32363a;
  text-align: center;
  flex: 1;
}
