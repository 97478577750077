cx-asm-main-ui {
  font-family: Arial, sans-serif;
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--cx-color-primary);
  ssab-cx-asm-toggle-ui{
    a .label{
      color: white;
    }
  }
  cx-form-errors{
    color: white;
  }
  ssab-cx-customer-selection{
    z-index: 1056;
  }

  .close,
  .logout {
    cursor: pointer;
    width: 16px;
    height: 16px;
    border: transparent;
    background-color: transparent;
  }

  .close {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z'/%3E%3C/svg%3E");
  }

  .logout {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%23d1e3ff' d='M11,2.7c1.2,0.6,2.2,1.5,2.9,2.6c1.3,1.9,1.5,4.4,0.6,6.5c-0.3,0.8-0.8,1.6-1.5,2.2c-0.6,0.6-1.4,1.1-2.2,1.5 C9.9,15.8,9,16,8,16c-0.9,0-1.9-0.2-2.7-0.5c-0.8-0.4-1.6-0.9-2.2-1.5c-0.6-0.6-1.1-1.4-1.5-2.2C0.7,9.6,0.9,7.2,2.1,5.3 c0.7-1.1,1.7-2,2.9-2.6v1.1C4.1,4.3,3.3,5.1,2.8,6C2.3,6.9,2,7.9,2,9c0,1.6,0.6,3.2,1.8,4.3c0.5,0.5,1.2,1,1.9,1.3 c1.5,0.6,3.2,0.6,4.7,0c0.7-0.3,1.4-0.7,1.9-1.3C13.4,12.1,14,10.6,14,9c0-1.1-0.3-2.1-0.8-3c-0.5-0.9-1.3-1.7-2.2-2.2 C11,3.8,11,2.7,11,2.7z M8,9C7.7,9,7.5,8.9,7.3,8.7C7.1,8.5,7,8.3,7,8V1c0-0.3,0.1-0.5,0.3-0.7c0.4-0.4,1-0.4,1.4,0 C8.9,0.5,9,0.7,9,1v7c0,0.3-0.1,0.5-0.3,0.7C8.5,8.9,8.2,9,8,9z'/%3E%3C/svg%3E%0A");
  }

  button[type='submit'] {
    padding: 0 12px;
    white-space: nowrap;
    border-radius: 4px;
    height: 36px;
    font-weight: 400;

    border-style: solid;
    border-width: 1px;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  .spinner {
    display: flex;
    justify-content: center;
    width: 100%;
    color: #0a6ed1;

    > div {
      width: 8px;
      height: 8px;
      margin: 6px;
      border-radius: 100%;
      background-color: currentColor;
      animation: spinner-dots-pulse 1s ease infinite;

      &:nth-child(1) {
        animation-delay: -0.2s;
      }

      @keyframes spinner-dots-pulse {
        0%,
        100%,
        60% {
          -webkit-transform: scale(1);
          transform: scale(1);
        }
        30% {
          -webkit-transform: scale(2);
          transform: scale(2);
        }
      }
    }
  }

  &.hidden {
    display: none;
  }

  // Top Bar
  .asm-bar {
    color: #fff;
    background-color: #354a5f;
    height: 48px;
    display: flex;
    padding: 0 2rem;
    justify-content: space-between;
    z-index: 1;

    &-branding {
      display: flex;
      align-items: center;

      .logo {
        margin-inline-end: 8px;
      }

      .asm-title {
        font-size: 16px;
        font-weight: 700;
      }
    }

    &-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  > *:nth-child(2) {
    padding: 1rem 2rem;
    display: flex;
    width: 100%;
  }

  // Input styles
  input {
    outline: 0;
    border: 1px solid #89919a;
    color: #32363a;
    background-color: #fff;
    border-radius: 4px;
    padding: 0 12px;
    height: 36px;

    &:focus {
      box-shadow: 0 0 0 1px #fafafa;
    }

    &:hover {
      border-color: #085caf;
    }
    &::placeholder {
      color: #74777a;
      font-style: italic;
    }
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575px) {
    .asm-bar-branding .asm-title {
      display: none;
    }

    .asm-alert {
      margin-top: 30px;
    }
  }
}
